html,
body,
#root {
  height: 100%;
}

.Header {
  display: flex;
  flex-direction: row;
  color: #fff;
  height: 80px;
}
.Header-container {
  background-color: rgb(48, 16, 0);
  margin-bottom: 4rem;
}
.Header-left {
  display: flex;
  width: 50%;
  position: relative;
}
.Header-right {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;
}
.Header-brand {
  height: 130px;
  position: absolute;
}

.table {
  min-width: 1200px;
}

.table tr.button {
  cursor: pointer;
}
